import React from "react";

const TextLogo = () => {
  return (
    <div className="border-2 border-green-600 p-2 w-fit">
      <h2 className="font-semibold flex items-center">
        <span className="bg-green-600 text-gray-50 h-full py-2 px-1">Energy</span>InOne
      </h2>
    </div>
  );
};

export default TextLogo;
