import React from "react";

const HomeCards = ({ data }) => {
  const { title, count } = data;
  return (
    <div className="flex-1 min-w-[200px] lg:min-w-max bg-gray-800 rounded-md shadow-md p-3">
      <h4 className="text-xl lg:text-3xl font-semibold text-gray-50">{title}</h4>
      <p className="font-bold text-green-500 text-xl">{count}</p>
    </div>
  );
};

export default HomeCards;
