import React from "react";

const TextInput = ({ label, value, onChange, inputType, inputName, className, placeholder }) => {
  return (
    <div className="grid my-4">
      {label && (
        <label htmlFor={inputName} className="capitalize text-gray-800 font-semibold mb-1.5">
          {inputName}
        </label>
      )}
      <input
        className={`border-[1.6px] border-gray-300 outline-none px-4 py-2 rounded-md ${className}`}
        type={inputType}
        name={inputName}
        value={value}
        onChange={onChange}
        placeholder={placeholder ? placeholder : ""}
      />
    </div>
  );
};

export default TextInput;
