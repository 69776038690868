export const baseUrl = "https://greenserver.onrender.com/api/v1/";

export const Links = [
  {
    id: 1,
    title: "Dashboard",
    to: "/",
    icon: "",
  },
  {
    id: 2,
    title: "Orders",
    to: "/order",
    icon: "",
  },
];

export const dashStats = [
  {
    id: 1,
    title: "Clients",
    count: 124,
  },
  {
    id: 2,
    title: "Petrol",
    count: 321,
  },
  {
    id: 3,
    title: "Diesel",
    count: 621,
  },
  {
    id: 4,
    title: "LPG",
    count: 221,
  },
  {
    id: 5,
    title: "Transport",
    count: 721,
  },
];

export const selectedDataArray = [
  {
    _id: "664b65baf77c282324e05d12",
    orderType: "diesel",
    paymentType: "usd",
    volume: "30",
    cost: "55",
    registration: "AEW3311",
    model: "jeep",
    clientName: "tapiwa karodza",
    clientPhone: "+263785404096",
    clientEmail: "nkarodza@gmail.com",
    deliveryDate: "06/06/2024",
    deliveryTime: "15:32",
    buyingFor: "other",
    otherName: "Aisha Karodza",
    otherPhone: "+263777721765",
    otherAddress: "10 rands road",
    otherSuburb: "westgate",
    coordinates: [],
    __v: 0,
  },
  {
    _id: "664b65c4f77c282324e05d14",
    orderType: "petrol",
    paymentType: "zig",
    volume: "23",
    cost: "322",
    registration: "AEW1133",
    model: "volkswagen",
    clientName: "Nyasha karodza",
    clientPhone: "+263785404096",
    clientEmail: "nkarodza@gmail.com",
    deliveryDate: "06/06/2024",
    deliveryTime: "15:44",
    buyingFor: "self",
    coordinates: [
      {
        latitude: "37.7749",
        longitude: "-122.4194",
        _id: "664b65c4f77c282324e05d15",
      },
    ],
    __v: 0,
  },
  {
    _id: "664b65dff77c282324e05d18",
    clientId: "664a66cb81390b800fe1ff87",
    orderType: "gas",
    paymentType: "usd",
    volume: "3",
    cost: "8",
    registration: "",
    model: "",
    clientName: "",
    clientPhone: "+263785404096",
    deliveryDate: "06/06/2024",
    deliveryTime: "18:44",
    buyingFor: "self",
    address: "310 dublin green",
    suburb: "emerald Hill",
    coordinates: [],
    __v: 0,
  },
  {
    _id: "664b65dff77c282324e05d19",
    clientId: "664a66cb81390b800fe1ff87",
    orderType: "transport",
    paymentType: "usd",
    volume: "3",
    cost: "8",
    registration: "",
    model: "",
    clientName: "",
    clientPhone: "+263785404096",
    deliveryDate: "06/06/2024",
    deliveryTime: "18:44",
    buyingFor: "self",
    address: "310 dublin green",
    suburb: "emerald Hill",
    coordinates: [],
    __v: 0,
  },
];
