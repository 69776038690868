import React from "react";
import { Link } from "react-router-dom";
import { Links } from "../utils/urls";
import TextLogo from "./TextLogo";

const SideBarNav = () => {
  return (
    <div className="bg-white hidden lg:flex lg:flex-col min-w-[300px] p-6">
      <div className="mb-4">
        <TextLogo />
      </div>
      <nav className="flex justify-between flex-col flex-1 my-4">
        <div className="flex justify-between flex-col gap-2">
          <h4 className=" font-bold text-gray-600 pb-2 mb-3 border-b">Main Menu</h4>
          {Links.map((link) => (
            <Link key={link.id} to={link.to}>
              {link.title}
            </Link>
          ))}
        </div>

        <div className="flex flex-col gap-3">
          <Link>Settings</Link>
          <button className="text-left w-fit" type="button">
            Logout
          </button>
        </div>
      </nav>
    </div>
  );
};

export default SideBarNav;
