import React, { useState } from "react";
import Avatar from "react-avatar";
import { GoChevronRight } from "react-icons/go";
import { IoMenu } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../context/appContext";
import { Links } from "../utils/urls";
import TextLogo from "./TextLogo";

const HeaderNav = () => {
  const { user, logoutUser } = useAppContext();
  let { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  let title = pathname.split("/")[1];

  const fullName = `${user?.name} ${user?.surname}`;

  const handleLogout = () => {
    setIsOpen(false);
    logoutUser();
  };

  return (
    <div className="border-b w-full py-4 mb-4 flex items-center justify-between">
      <div className="flex items-center gap-3">
        <button className="lg:hidden" onClick={() => setIsOpen(true)}>
          <IoMenu size={34} />
        </button>
        <h4 className="uppercase font-semibold tracking-wide">{title ? title : "Dashboard"}</h4>
      </div>
      <Avatar name={fullName} size="45px" />

      {isOpen && (
        <div className="fixed top-0 bottom-0 left-0 right-0 bg-white lg:hidden flex flex-col justify-center p-4">
          <div>
            <TextLogo />
          </div>
          <div className="flex-1 flex flex-col items-center justify-center gap-4">
            {Links.map((link) => (
              <Link
                className=" w-full flex items-center justify-between bg-gray-50 px-3 font-semibold text-xl text-gray-700 py-3"
                onClick={() => setIsOpen(false)}
                key={link.id}
                to={link.to}
              >
                {link.title}
                <GoChevronRight size={34} />
              </Link>
            ))}
          </div>
          {/* logout */}
          <button onClick={handleLogout} className="bg-green-500 text-xl font-semibold rounded-md py-2">
            Sign out
          </button>
        </div>
      )}
    </div>
  );
};

export default HeaderNav;
