import React from "react";
import { Outlet } from "react-router-dom";
import { HeaderNav, Loading, SideBarNav } from "../components";
import { useAppContext } from "../context/appContext";

const Root = () => {
  const { isLoading } = useAppContext();

  return (
    <div className="flex min-h-screen lg:h-screen bg-gray-50 overflow-hidden">
      <SideBarNav />
      <div className="h-full w-full px-4 ">
        <HeaderNav />
        {isLoading ? <Loading /> : <Outlet />}
      </div>
    </div>
  );
};

export default Root;
