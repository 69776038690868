import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert, TextInput, TextLogo } from "../components";
import { useAppContext } from "../context/appContext";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const { isLoading, showAlert, displayAlert, loginUser, user } = useAppContext();
  const [values, setValues] = useState(initialValues);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = values;

    if (!email || !password) {
      displayAlert();
      return;
    }

    const currentUser = { email, password };

    loginUser(currentUser);
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [user, navigate]);

  return (
    <div className="h-[calc(100vh-0px)] bg-gray-100 p-6 lg:flex items-center justify-center">
      <div className="flex-1 hidden lg:grid items-center justify-center h-full ">
        <TextLogo />
      </div>
      <div className="bg-white rounded-md p-6 lg:px-24 lg:min-w-[600px] flex flex-col  justify-center space-y-9 h-full content-center">
        <div>
          <div className="lg:hidden flex justify-center pb-4 lg:pb-0">
            <TextLogo />
          </div>
          <h4 className="text-center font-bold text-2xl text-gray-700">Sign In</h4>
        </div>

        <form onSubmit={handleSubmit}>
          {showAlert && <Alert />}
          <TextInput label inputName="email" inputType="email" value={values.email} onChange={handleChange} />
          <TextInput label inputName="password" inputType="password" value={values.password} onChange={handleChange} />

          <div className=" flex justify-end">
            <Link className="font-semibold text-sm text-gray-800">Forgot Password ?</Link>
          </div>

          <button
            className={`${
              isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-green-300 hover:bg-green-200"
            } w-full py-2 rounded-md mt-4`}
            disabled={isLoading}
          >
            {isLoading ? "Please wait..." : "Sign in"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
