import React from "react";

const Loading = () => {
  return (
    <div className="h-[calc(100vh-100px)] lg:h-full w-full flex items-center justify-center">
      <div className="flex flex-col justify-center item-center ">
        <div className="bg-green-600 h-8 w-8 animate-bounce rounded-full self-center"></div>
        <p className="text-gray-600 text-center text-xl font-semibold mt-2">Loading please wait...</p>
      </div>
    </div>
  );
};

export default Loading;
