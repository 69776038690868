import React from "react";
import { HomeCards, TabbedDataHome } from "../components";
import { dashStats } from "../utils/urls";

const Dashboard = () => {
  return (
    <div className="">
      <h4 className="text-2xl font-bold leading-6 text-gray-600 mb-2">At A Glance</h4>

      <div className="flex items-center gap-3 overflow-x-scroll lg:overflow-auto pb-5">
        {dashStats.map((stats, i) => (
          <HomeCards key={stats.id} data={stats} />
        ))}
      </div>
      <div>
        <h4 className="text-2xl font-bold leading-6 text-gray-600 mb-2">Recent Activities</h4>
        <TabbedDataHome />
      </div>
    </div>
  );
};

export default Dashboard;
