import React, { useEffect, useState } from "react";
import { selectedDataArray } from "../utils/urls";

const TabbedDataHome = () => {
  const [selectedOption, setSelectedOption] = useState("client");

  useEffect(() => {
    // code that fetches selected database and renders them the same via creating a new array to display
  }, [selectedOption]);

  return (
    <div>
      {/* selector */}
      <div className="bg-gray-200 rounded-md p-1 w-fit flex items-center gap-3">
        <button
          onClick={() => setSelectedOption("client")}
          className={`${
            selectedOption === "client" ? "bg-green-600 text-gray-50" : "bg-transparent"
          } text-sm font-semibold px-2 py-1 rounded-md text-gray-700`}
          type="button"
        >
          Clients
        </button>
        <button
          onClick={() => setSelectedOption("petrol")}
          className={`${
            selectedOption === "petrol" ? "bg-green-600 text-gray-50" : "bg-transparent"
          } text-sm font-semibold px-2 py-1 rounded-md text-gray-700`}
          type="button"
        >
          Petrol
        </button>
        <button
          onClick={() => setSelectedOption("diesel")}
          className={`${
            selectedOption === "diesel" ? "bg-green-600 text-gray-50" : "bg-transparent"
          } text-sm font-semibold px-2 py-1 rounded-md text-gray-700`}
          type="button"
        >
          Diesel
        </button>
        <button
          onClick={() => setSelectedOption("gas")}
          className={`${
            selectedOption === "gas" ? "bg-green-600 text-gray-50" : "bg-transparent"
          } text-sm font-semibold px-2 py-1 rounded-md text-gray-700`}
          type="button"
        >
          Gas
        </button>
        <button
          onClick={() => setSelectedOption("transport")}
          className={`${
            selectedOption === "transport" ? "bg-green-600 text-gray-50" : "bg-transparent"
          } text-sm font-semibold px-2 py-1 rounded-md text-gray-700`}
          type="button"
        >
          Transport
        </button>
      </div>
      {/* display */}
      <div className="bg-gray-200 p-2 rounded-md my-3 flex flex-col space-y-2">
        {selectedDataArray.map((data) => (
          <div key={data._id} className="grid grid-cols-3 lg:grid-cols-5 bg-white rounded-md gap-2 p-3">
            <div>
              <p className="capitalize truncate">{data.clientName ? data.clientName : "ReplaceIDDetails"}</p>
              <p className="truncate">{data.clientPhone}</p>
              <p className="truncate">
                {data.address ? (
                  data.address
                ) : (
                  <span>
                    {data.coordinates[0]?.latitude} {data.coordinates[0]?.longitude}
                  </span>
                )}
              </p>
            </div>
            {/*  */}
            <div className="hidden lg:block">
              <p className="capitalize truncate">Date: {data.deliveryDate}</p>
              <p className="capitalize truncate">Time: {data.deliveryTime}</p>
            </div>
            {/*  */}
            <div className="hidden lg:block">
              <p className="capitalize truncate">For: {data.buyingFor}</p>
              <p className="truncate capitalize">Type: {data.orderType}</p>
            </div>
            {/*  */}
            <div className="flex flex-col items-end lg:items-start">
              <p className="truncate capitalize">
                {data.volume}{" "}
                {data.orderType === "gas"
                  ? "kg"
                  : data.orderType === "petrol" || data.orderType === "diesel"
                  ? "Ltr"
                  : "Km"}
              </p>
              <p>
                $ {data.cost} {data.paymentType === "usd" ? "USD" : "ZiG"}
              </p>
            </div>
            {/* action */}
            <div className="flex justify-end items-center">
              <button className="px-3 py-2 bg-gray-800 rounded-md text-gray-50">More</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabbedDataHome;
